const reviews = [
  {
    id: 1,
    name: "susan smith",
    job: "designer",
    image:
      "https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883334/person-1_rfzshl.jpg",
    text:
      "Nudge inter darts sun orca ingham jogging your jar cockie hand jana yoyo ana pumba inan your jarBanh mi owner shin jar knee two cower gin and your jar",
  },
  {
    id: 2,
    name: "anna johnson",
    job: "constructor",
    image:
      "https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883409/person-2_np9x5l.jpg",
    text:
      "Good or bad John inan your jar Nana summer air nah Jen and law Markov tar arrow on tour Summer air coffee ship eagle John and washer Daniel Summer air banh mi owner ship dunny charger bond and summer air  kudos summer air",
  },
  {
    id: 3,
    name: "peter jones",
    job: "artist",
    image:
      "https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883417/person-3_ipa0mj.jpg",
    text:
      "Sriracha literally flexitarian irony, vape marfa unicorn. Glossier tattooed 8-bit, fixie waistcoat offal activated charcoal slow-carb marfa hell of pabst raclette post-ironic jianbing swag.",
  },
  {
    id: 4,
    name: "bill anderson",
    job: "designer",
    image:
      "https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883423/person-4_t9nxjt.jpg",
    text:
      "And there was silence in our world could of known… if I’d have known And there was silence in our world could of known… if I’d have known Cheating book target the ganges gavel go go gooooo Open Gang numb style ",
  },
];

export default reviews;
